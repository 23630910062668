// axios请求的封装文件
import axios from 'axios';
import { message } from '@tencent/tea-component';
import { v4 as uuidv4 } from 'uuid';
import md5 from 'crypto-js/md5';
import { enc } from 'crypto-js';

axios.defaults.timeout = 50000;

// 请求拦截器,处理统一loading,token,统一的header等信息
axios.interceptors.request.use((config): any => {
  config.headers.http_header_trace_id = uuidv4().replaceAll('-', '');
  return config;
});
export const BASE_URL = import.meta.env.DEV  ? '/dev' : '';
// 生成token参数
const getToken = () => {
  const unixTime = Math.round(new Date().getTime() / 1000);
  const appid = 'fake_ads_platform';
  const password = 'TENTCENTFAKEADS@2021';
  const md5Auth =  md5(`${appid}${password}`).toString(enc.Hex);
  const md5Sign =  md5(`${unixTime}${md5Auth}`).toString(enc.Hex);


  const authParam = {
    signid: md5Sign,
    unixTime,
    appid,
  };

  // 鉴权参数固定在post参数
  return {
    ...authParam,
  };
};

export const post = function (api: string, data?: any): Promise<any> {
  const authToken = getToken();
  return axios
    .post(`${BASE_URL}${api}`, { ...data, ...authToken })
    .then((res) => {
      if (res?.status !== 200) {
        throw new Error(res.data);
      }

      return res.data;
    })
    .catch((errorMessage) => {
      handleError(errorMessage.toString());
    });
};

// 错误处理逻辑
function handleError(msg?: string): void {
  message.error({ content: msg || '系统错误，请稍后重试。' });
}
