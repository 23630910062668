import React, { useEffect, Suspense, lazy } from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider, useDispatch } from 'react-redux';
import thunk from 'redux-thunk';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { ConfigProvider } from '@tencent/tea-component';
import { HISTORY_CONTEXT } from './history-context';
import commonReducer from '@src/store/reducer';
import Loading from '@src/components/Loading';
import { fetchSysInfo } from '@src/store/action/common';


const Home = lazy(() => import('@src/routes/home/Home'));


// 新增全局Store
const AppWrapper = (props: any) => {
  const store = createStore(commonReducer, applyMiddleware(thunk));
  return (
    <Provider store={store}>
      {props?.children}
    </Provider>
  );
};

const LoadingWrapper = () => {
  const coadingWrapperClassName = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  };
  return <div style={coadingWrapperClassName}>
    <Loading/>
  </div>;
};

const App = () => {
  const dispatch: any = useDispatch();
  useEffect(() => {
    dispatch(fetchSysInfo({ id: 1 }));
  }, []);

  return (
    <Router basename={import.meta.env.BASE_URL}>
      <HISTORY_CONTEXT.Provider value={{ history }}>
        <ConfigProvider>
          {/* <Layout menu={menu}> */}
          <Suspense fallback={<LoadingWrapper/>}>
            <Switch>
              <Route
                  key="/login"
                  component={Home}
              />
              <Route path="*">
                <Redirect to="/login" />
              </Route>
            </Switch>
          </Suspense>
        </ConfigProvider>
      </HISTORY_CONTEXT.Provider>
    </Router>
  );
};

export async function routes() {
  ReactDOM.render(
    (<AppWrapper><App  /></AppWrapper>),
    document.querySelector('#root'),
  );
}
