// 全局的数据
// 用户信息数据结构
interface UserInfo {
  username: String;
  userrole?: String;
  location: Array<Object>; // 用户的当前所属区域
  rawLocation: Array<Object>; // 用户允许的可选择区域
  company?: string;
  menuList?: Array<Object>
}
// 系统数据结构
export interface SysInfo {
  id: Number, // 系统信息ID
  system_name: string, // 系统标题
  system_logo: string,  // 系统图标URL
  system_login: string,
  updatetime: string,
  operator: string,
  system_favicon: string; // 系统faviconUrl
}

interface GlobalInfo {
  userInfo: UserInfo;
  sysInfo: SysInfo;
}
const initialState: GlobalInfo = {
  userInfo: {
    username: '-',
    location: [],
    rawLocation: [],
    menuList: [],
  },
  sysInfo: {
    id: 1,
    system_name: '',
    system_favicon: '',
    system_login: '',
    system_logo: '',
    updatetime: '',
    operator: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'updateUserInfo': {
      return { ...state, userInfo: action.payload };
    }
    case 'updateSysInfo': {
      return { ...state, sysInfo: action.payload };
    }
    default:
      return state;
  }
};
