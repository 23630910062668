import { combineReducers } from 'redux';
import common from './common';

const COMBINED: any = combineReducers({
  common,
});

export type AppState = ReturnType<typeof COMBINED>;

export default COMBINED;
