// 导入依赖
import { app } from '@tea/app';


// 导入样式
import '@tencent/tea-component/lib/tea.css';
import './assets/css/global.css';
import './app.less';


// 注册路由表/左侧菜单
app.routes();
