import { post } from '@src/utils/request';
import cgi from '@src/configs/cgi';

export function fetchUserInfo(dispatch) {
  return post(cgi.getUserInfoAPI).then((data: any) => {
    dispatch({
      type: 'updateUserInfo',
      payload: data?.userInfo || {},
    });
  });
}


export function fetchSysInfo(params) {
  return function (dispatch) {
    post(cgi.getSysInfoAPI, params).then((res: any) => {
      if (res.reCode === 0) {
        dispatch({
          type: 'updateSysInfo',
          payload: res?.data,
        });
      }
    });
  };
  return;
}
