import commonApis from './cgiCommon';
import cgiSystem from './cgiSystem';
const APIS = {
  /**
   * 全局接口
   */
  ...commonApis,
  ...cgiSystem,
};
export default APIS;
