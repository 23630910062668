import * as React from 'react';
import { StatusTip } from '@tencent/tea-component';
import './loading.less';

const { LoadingTip } = StatusTip;

const Loading = () => (
  <div className="loading">
    <LoadingTip className="spin" />
  </div>
);

export default Loading;
