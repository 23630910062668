const COMMON_APIS = {
  getSysInfoAPI: '/getSystemInfo',

};
const prefix = 'privatelogin/login/usermanage';
Object.entries(COMMON_APIS).forEach((api) => {
  const [key, value] = api;
  COMMON_APIS[key] = `/${prefix}${value}`;
});
export default COMMON_APIS;
